<template>
  <PageContent
    class="fiche-fourniture"
    :prev-page="prevPage"
  >
    <template #header-right>
      <Badge
        v-if="badgeDispoInfo.variant && !computedIsProductAvailable"
        id="header_badge"
        :variant="badgeDispoInfo.variant"
        :title="badgeDispoInfo.title"
        medium
      />
      <ButtonGroup>
        <ButtonClassic
          variant="solid"
          icon="right"
          @click="copyToClipboard(`${currentUrl}?id_organisme=${organismeActuel.id}`)"
        >
          <template #right-icon>
            <UilShareAlt />
          </template>
        </ButtonClassic>
      </ButtonGroup>
    </template>

    <template
      v-if="product"
      #content
    >
      <FournitureOverview
        :product="product"
        @add-to-liste="checkToList"
      />
      <section
        v-if="description"
        id="product_description"
        description
        class="grid-zone"
      >
        <div id="description">
          <h2 class="s4 bold">
            {{ $t('produit.description') }}
          </h2>
          <div
            class="text-regular"
            v-html="description"
          />
        </div>
      </section>
      <ProductRelations
        :products="similarProducts"
        :title="$t('produit.similaire')"
      />
      <!--  <ProductRelations :product="product" /> -->
      <ModalListesSelection
        id="modal_listes_selection"
        :listes="activeLists"
        @open-creation-liste="$modal.show('modal_listes_creation')"
      />
    </template>
    <template
      v-else
      #content
    >
      <div>
        <InfiniteLoader />
      </div>
    </template>
  </PageContent>
</template>

<script>
import {
  ButtonClassic,
  ButtonGroup,
  PageContent,
  InfiniteLoader,
} from "@lde/core_lde_vue";

import Badge from "@/components/Badge.vue";
import FournitureOverview from "@/components/fournitures/FournitureOverview.vue";
import ProductRelations from "@/components/products/ProductRelations.vue";
import ModalListesSelection from "@/components/modals/ModalListesSelection.vue";

import mixinFicheProduit from "@/mixins/mixinFicheProduit";
import mixinDisponibilite from "@/mixins/mixinDisponibilite";

import { UilShareAlt } from "@iconscout/vue-unicons";

import Api from "@/modules/axios";

import { mapGetters } from "vuex";

/**
 * Vue d'une fourniture en particulier avec toutes ses informations.
 */
export default {
  name: "FicheFourniture",
  components: {
    PageContent,
    ButtonGroup,
    ButtonClassic,
    Badge,
    FournitureOverview,
    ProductRelations,
    ModalListesSelection,
    InfiniteLoader,
    UilShareAlt,
  },
  mixins: [mixinFicheProduit, mixinDisponibilite],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevPage = from;
    });
  },
  data() {
    return {
      prevPage: null,
      currentUrl: window.location.href,
      idOrganisme: null,
      similarProducts: [],
      similarProductsAreLoading: false,
    };
  },
  computed: {
    ...mapGetters(["organismeActuel", "isFetchingActiveLists", "activeLists"]),
    description() {
      let description = "";

      if (this.product.description_lde) {
        description += this.product.description_lde;
      } else if (this.product.description) {
        description += this.product.description;
      }

      if (this.product.info_environnement) {
        description += `<br/>${this.product.info_environnement}`;
      }

      return description.split("\n").join("<br />").split("\r").join("");
    },
  },
  methods: {
    /**
     * Récupère les informations du produit.
     * @returns {Promise} Infos du produit.
     */
    fetchProduct() {
      return Api().get(`/fourniture/${this.$route.params.id}/`)
        .then(({ data }) => {
          this.product = data;
          this.product.offre_defaut.quantite = 1;
          this.fetchSimilarArticles();
        });
    },
    /**
     * Récupère les articles de même famille
     */
    fetchSimilarArticles() {
      this.similarProductsAreLoading = true;
      return Api().get(`/fourniture/${this.product.id}/related/`)
        .then((res) => {
          this.similarProducts = res.data;
          this.similarProductsAreLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/views/fiche_produit.scss";
@use "~@lde/core_lde_vue/dist/assets/styles/_variables.scss" as var;
@use "~@lde/core_lde_vue/dist/assets/styles/_effects.scss" as effect;

.fiche-fourniture {
  #product_description { margin-bottom: var.$space-x-large; }

  #description {
    grid-column: 2/-2;
    margin: 0;
    padding: var.$space-medium var.$space-medium var.$space-x-large;
    border-radius: var.$border-radius-x-tiny;
    @include effect.whity-bumped;
    h2 { margin-bottom: var.$space-small; }
  }

  .product-relations:not(:last-of-type) { margin-bottom: var.$space-x-large; }
}
</style>
