<template>
  <Modal
    :id="id"
    modal-class="modal-listes-selection"
    @change="!$event && hide()"
  >
    <template #title>
      {{ $t('liste.ajout-liste-choix') }}
    </template>
    <template #content>
      <h2 class="text-regular bold">
        {{ $t('liste.ajouter-selection-dans-liste') }}
      </h2>
      <p class="text-regular">
        {{ $t('liste.restera-activee') }}
      </p>
      <InfiniteLoader v-if="listes.length < 1" />
      <ul
        v-else
        id="checkbox_items"
      >
        <li
          v-for="liste in listes"
          :key="liste.id"
        >
          <ButtonToggle
            v-if="liste.id"
            :value="selectedList?.id.toString()"
            :label="liste.libelle"
            :name="liste.libelle"
            :input-value="liste?.id.toString()"
            @change="selectedList = liste"
          />
        </li>
      </ul>
    </template>
    <template #footer>
      <div>
        <ButtonClassic
          variant="special"
          :label="$t('action.creer-liste')"
          :disabled="isImpersonating"
          color="primary"
          icon="left"
          @click="openCreationListe()"
        >
          <template #left-icon>
            <UilPlus />
          </template>
        </ButtonClassic>
        <ButtonClassic
          variant="solid"
          :label="$t('action.valider')"
          color="secondary"
          icon="right"
          :disabled="!selectedList?.id || isLoading"
          @click="selectList()"
        >
          <template #right-icon>
            <UilCheckCircle />
          </template>
        </ButtonClassic>
      </div>
      <button
        class="button text-medium underline"
        @click="toAllLists()"
      >
        {{ $t('action.acces-vos-listes') }}
      </button>
    </template>
  </Modal>
</template>

<script>
import {
  ButtonClassic,
  ButtonToggle,
  Modal,
  InfiniteLoader,
} from "@lde/core_lde_vue";
import mixinToListe from "@/mixins/mixinToListe";
import { mapGetters } from "vuex";

import { UilPlus, UilCheckCircle } from "@iconscout/vue-unicons";

/**
 * Modale permettant de sélectionner une liste parmi celles existantes.
 */
export default {
  name: "ModalListesSelection",
  components: {
    Modal,
    ButtonClassic,
    ButtonToggle,
    UilPlus,
    UilCheckCircle,
    InfiniteLoader,
  },
  mixins: [mixinToListe],
  props: {
    /**
     * Identifiant HTML de la modale.
     * Permet de changer l'id pour ne pas ouvrir la même instance de cette modale plusieurs fois si elle est déclarée
     * à différnts endroits.
     */
    id: {
      type: String,
      default: "modal_listes_selection",
    },
    listes: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    /**
     * Déclenché à la fermeture de la modale si elle précède l'affichage de la modale de création de liste.
     */
    "open-creation-liste",
    /**
     * Déclenché au déplacement de lignes vers une autre liste.
     */
    "move-to-list",
  ],
  data() {
    return {
      selectedList: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(["selectedLines"]),
  },
  methods: {
    /**
     * Ferme la modale et appelle la modale de création de liste.
     */
    openCreationListe() {
      this.hide(false);
      this.$emit("open-creation-liste");
    },
    /**
     * Ferme la modale.
     */
    hide() {
      this.$modal.hide(this.id);
    },
    /**
     * Redirige sur la page des listes.
     */
    toAllLists() {
      this.hide();
      this.$router.push({ name: "listes_devis_listes" });
    },
    /**
     * Sélectionne la liste sur laquelle effectuer l'action en cours.
     */
    selectList() {
      if (this.selectedList) {
        this.isLoading = true;
        if (this.id === "modal_listes_selection_move") {
          this.$emit("move-to-list", this.selectedList.id);
        } else if (this.selectedLines.length) {
          if (this.$route.name.includes("commandes_factures_commandes")) {
            this.addOrderLinesToList({ liste: this.selectedList, commandes: this.selectedLines });
          } else {
            this.addOffersToList({ liste: this.selectedList, lignes: this.selectedLines });
          }
        }
        this.isLoading = false;
        this.hide();
      }
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/modals/modal_listes_selection.scss";
</style>
